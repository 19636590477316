import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SearchTopNavBar from "./SearchTopNavBar/SearchTopNavBar";
import RenderDropdownNavbar from "./RenderDropdownNavbar";
import { getUserTypeSync, isLoggedInSync } from "../../util/getUserToken";
import RightMenuTopNavbar from "./RightMenuTopNavbar";
import { StylesTopNavBarButtonItems } from "../../style/styles";
import CountMessagesNavBarItem from "./CountMessagesNavBarItem";
import { ReactComponent as AppLogo } from "../../assets/logo.svg";
import { styled } from "@mui/material/styles";
import { useGetQuotaUsage } from "../../store/api/stripeApi";

const LightButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  padding: "6px 20px",
  border: "none",
  fontWeight: "500",
  backgroundColor: "white",
  color: "black",
  borderRadius: "6rem",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#f5f5f5",
  },
});

// ============================================================================================

const drawerWidth = 240;
const MenuItemsGap = 3.5;

const navItemsPublic = [
  {
    id: 1,
    name: "About",
    path: "/about-us",
  },
  {
    id: "resources",
    name: "Resources",
    children: [
      {
        id: 1,
        name: "Blogs",
        path: "/resources/blogs",
      },

      {
        id: 2,
        name: "Courses",
        path: "/resources/courses",
      },
      {
        id: 3,
        name: "FAQs",
        path: "/resources/faqs",
      }
    ],
  },
  {
    id: "subscription-plans",
    name: "Pricing",
    children: [
      {
        id: 1,
        name: "Immigration Firm",
        path: "/subscription-plans/immigration-firm",
      },

      {
        id: 2,
        name: "Applicant",
        path: "/subscription-plans/applicant",
      },

    ],
  },
];

const navItemsForApplicant = [
  {
    id: "job-listing",
    name: "Job Listing",
    children: [
      {
        id: 1,
        name: "Recommended Jobs",
        path: "/applicant/search-jobs?category=recommendation",
      },
      {
        id: 2,
        name: "Saved Jobs",
        path: "/applicant/search-jobs?category=savedJobs",
      },
      {
        id: 3,
        name: "Applied Jobs",
        path: "/applicant/search-jobs?category=appliedJobs",
      },
      {
        id: 4,
        name: "New Listings",
        path: "/applicant/search-jobs?category=newListings",
      },
    ],
  },
  {
    id: "search-companies",
    name: "Companies",
    path: "/applicant/search-companies",
  },
  {
    id: "resources",
    name: "Resources",
    children: [
      {
        id: 1,
        name: "Blogs",
        path: "/applicant/blogs",
      },
      {
        id: 2,
        name: "FAQs",
        path: "/applicant/faqs",
      },
      {
        id: 3,
        name: "Courses",
        path: "/applicant/resources",
      },
      {
        id: 4,
        name: "Subscription Plans",
        path: "/applicant/subscription-plans",
      },
    ],
  },
  {
    id: "messages",
    name: "Message",
    path: "/messages",
    hideOnLargeScreen: true,
  },
];

const navItemsForImmigrationFirmDefault = [
  {
    id: "jobs",
    name: "Jobs",
    children: [
      {
        id: 1,
        name: "Add new Job",
        path: "/immigration-firm/jobs/new",
      },
      {
        id: 2,
        name: "Manage Jobs",
        path: "/immigration-firm/jobs/manage",
      },
    ],
  },
  // {
  //   id: "job-seekers-search",
  //   name: "Job Seekers",
  //   path: "/immigration-firm/search-job-seekers",
  // },
  {
    id: "resources",
    name: "Resources",
    children: [
      {
        id: 1,
        name: "Blogs",
        path: "/immigration-firm/blogs",
      },
      {
        id: 2,
        name: "FAQs",
        path: "/immigration-firm/faqs",
      },
      {
        id: 3,
        name: "Courses",
        path: "/immigration-firm/resources",
      },
      {
        id: 4,
        name: "Subscription Plans",
        path: "/immigration-firm/subscription-plans",
      },
    ],
  },
  {
    id: "messages",
    name: "Message",
    path: "/messages",
    hideOnLargeScreen: true,
  },
];

const jobSeekerItem = {
  id: "job-seekers-search",
  name: "Job Seekers",
  path: "/immigration-firm/search-job-seekers",
};

function TopNavBar(props) {
  const userType = getUserTypeSync();
  const isLoggedIn = isLoggedInSync();
  const { data: quotaUsage, isLoading: initLoading } = useGetQuotaUsage();

  const [navItemsForImmigrationFirm, setNavItemsForImmigrationFirm] =
    React.useState(navItemsForImmigrationFirmDefault);

  React.useEffect(() => {
    if (quotaUsage?.planType && userType === "immigration-firm" && quotaUsage?.planType !== "free") {
      // add jobSeekerItem at index 1
      // setNavItemsForImmigrationFirm((prevNavItems) => {
      //   return [prevNavItems[0], jobSeekerItem, ...prevNavItems.slice(1)];
      // });
      // upper code will add jobSeekerItem everytime, we need to add one time only
      setNavItemsForImmigrationFirm((prevNavItems) => {
        if (prevNavItems[1]?.id !== jobSeekerItem.id) {
          return [prevNavItems[0], jobSeekerItem, ...prevNavItems.slice(1)];
        }
        return prevNavItems;
      });
    }
  }, [quotaUsage, userType]);

  const navItems = userType
    ? userType === "applicant"
      ? navItemsForApplicant
      : navItemsForImmigrationFirm
    : navItemsPublic;

  const HomePage = userType
    ? userType === "applicant"
      ? "/applicant"
      : "/immigration-firm"
    : "/";

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Link to={HomePage} style={{ textDecoration: "none", color: "inherit" }}>
        <Box sx={{ backgroundColor: "#1d1d1d", "&:hover": { opacity: 0.8 } }}>
          <AppLogo
            style={{ width: "auto", height: "40px", marginTop: "7px" }}
          />
        </Box>
      </Link>
      <Divider />
      <List>
        {navItems.map((item) =>
          item?.path ? (
            <ListItem key={item.id} disablePadding>
              <Link
                to={item.path}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  width: "100%",
                }}
              >
                <ListItemButton sx={{ textAlign: "center" }}>
                  <ListItemText primary={item?.name} />
                </ListItemButton>
              </Link>
            </ListItem>
          ) : (
            // list all children
            item?.children.map((child) => (
              <ListItem key={child.id} disablePadding>
                <Link
                  to={child.path}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                  }}
                >
                  <ListItemButton sx={{ textAlign: "center" }}>
                    <ListItemText primary={child?.name} />
                  </ListItemButton>
                </Link>
              </ListItem>
            ))
          )
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar sx={{ background: "black", boxShadow: "none" }} component="nav">
          <Container>
            <Toolbar
              disableGutters
              sx={{
                ".MuiToolbar-root": {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ display: "flex", gap: 0, alignItems: "center" }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: "none" } }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Link
                    to={HomePage}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Box sx={{ "&:hover": { opacity: 0.8 } }}>
                      <AppLogo
                        style={{
                          width: "auto",
                          height: "40px",
                          marginTop: "7px",
                        }}
                      />
                    </Box>
                  </Link>

                  <Box
                    sx={{
                      ml: { md: 5 },
                      display: { xs: "none", md: "flex" },
                      gap: MenuItemsGap,
                    }}
                  >
                    {navItems
                      .filter((item) => !item.hideOnLargeScreen)
                      .map((item) =>
                        Array.isArray(item?.children) ? (
                          <RenderDropdownNavbar item={item} key={item.id} />
                        ) : (
                          <Link
                            to={item.path}
                            key={item.id}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <Button sx={StylesTopNavBarButtonItems}>
                              {item?.name}
                            </Button>
                          </Link>
                        )
                      )}
                  </Box>

                  {isLoggedIn && (
                    <>
                      <Box sx={{ ml: MenuItemsGap }}>
                        <CountMessagesNavBarItem />
                      </Box>
                      <Box
                        sx={{
                          ml: MenuItemsGap,
                          display: { xs: "none", lg: "block" },
                        }}
                      >
                        <SearchTopNavBar />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                {isLoggedIn ? (
                  <RightMenuTopNavbar />
                ) : (
                  <>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                      <Link to="/auth/login">
                        <Button
                          sx={{
                            "&:hover": { opacity: 0.8 },
                            textTransform: "none",
                            color: "#fff",
                          }}
                        >
                          Post a Job
                        </Button>
                      </Link>
                      <Typography
                        sx={{
                          userSelect: "none",
                          mt: 0.875,
                          mx: 2.5,
                          color: "#fff",
                        }}
                      >
                        |
                      </Typography>
                    </Box>

                    <Link to="/auth/login">
                      <Button
                        sx={{
                          "&:hover": { opacity: 0.8 },
                          textTransform: "none",
                          color: "#fff",
                        }}
                      >
                        Login
                      </Button>
                    </Link>
                    {/* <LoginBtnAndModal /> */}
                    <Link to="/auth/register">
                      <LightButton variant="contained" sx={{ ml: 2 }}>
                        Sign Up
                      </LightButton>
                    </Link>
                  </>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>

      {/* DIV ADDED TO TAKE SPACE OF FIXED TOPNAVBAR */}
      <Box sx={{ height: { xs: "56px", sm: "64px", md: "64px" } }} />
    </>
  );
}

TopNavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default TopNavBar;
